import * as React from 'react'
import loadable from '@loadable/component'

const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const SelectDay = loadable(() =>
    import('/src/components/Form/Specialised/SelectDay')
)
const SelectMonth = loadable(() =>
    import('/src/components/Form/Specialised/SelectMonth')
)
const SelectYear = loadable(() =>
    import('/src/components/Form/Specialised/SelectYear')
)
const TextField = loadable(() => import('/src/components/Form/TextField'))

const StepUserDetails = () => {
    return (
        <>
            <h2 className="c-wizard__step-intro">About You</h2>

            <div className="c-wizard__form-step">
                <FormRow>
                    <TextField
                        label={'Your full legal name'}
                        name={'name'}
                        placeholder={'e.g. Katie Jones'}
                        required
                    />
                </FormRow>

                <FormRow>
                    <FieldSet legend={'Your date of birth*'}>
                        <FieldSpacer grow={'stretch'}>
                            <SelectDay name={'dobDay'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <SelectMonth name={'dobMonth'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <SelectYear
                                name={'dobYear'}
                                minAge={18}
                                maxAge={90}
                            />
                        </FieldSpacer>
                    </FieldSet>
                </FormRow>

                <FormRow>
                    <TextField
                        label={'First line of your address'}
                        name={'address'}
                        placeholder={'e.g. 105 Dover Road'}
                    />
                </FormRow>

                <FormRow>
                    <TextField
                        label={'Postcode'}
                        name={'postcode'}
                        placeholder={'e.g. ZE2 7UL'}
                    />
                </FormRow>
            </div>
        </>
    )
}

export default StepUserDetails
